import { mapGetters } from "vuex";
import BlogCoverPreview from "@/components/BlogCoverPreview";
import Loading from "@/components/Loading";
import firebase from "firebase/app";
import "firebase/storage";
import db from "@/firebase/firebaseInit";
import { VueEditor, Quill } from "vue2-editor";

window.Quill = Quill;
const ImageResize = require("quill-image-resize-module").default;
Quill.register("modules/imageResize", ImageResize);

export default {
  name: "CreatePost",
  data() {
    return {
      error: null,
      errorMsg: null,
      loading: null,
      routeID: null,
      currentBlog: null,
      editorSettings: {
        modules: {
          imageResize: {},
        },
      },
    };
  },
  components: {
    BlogCoverPreview,
    Loading,
    VueEditor,
  },
  computed: {
    ...mapGetters({
      currentBlogTitle: "getCurrentBlogTitle",
      currentBlogHTML: "getCurrentBlogHTML",
      allPosts: "getAllBlogPost",
      fileChanged: "getFileChanged",
    }),
    file: {
      get() {
        return (
          this.$store.state.blogPhotoCoverRaw ||
          this.$store.state.blogPhotoFileURL
        );
      },
      set(payload) {
        console.log("azezaeza", payload);
        const fileName = payload.name;
        this.$store.commit("fileNameChange", fileName);
        this.$store.commit("fileRaw", payload);
        this.$store.commit("createFileURL", URL.createObjectURL(payload));
      },
    },
    profileId() {
      return this.$store.state.profileId;
    },
    blogCoverPhotoName() {
      return this.$store.state.blogPhotoName;
    },
    blogTitle: {
      get() {
        return this.$store.state.blogTitle;
      },
      set(payload) {
        this.$store.commit("updateBlogTitle", payload);
      },
    },
    blogHTML: {
      get() {
        return this.$store.state.blogHTML;
      },
      set(payload) {
        this.$store.commit("newBlogPost", payload);
      },
    },
  },
  async mounted() {
    this.routeID = this.$route.params.blogid;
    this.currentBlog = await this.allPosts.filter((post) => {
      return post.blogID === this.routeID;
    });
    let data = this.currentBlog[0];
    const blogTitle = this.currentBlogTitle;
    const blogHTML = this.currentBlogHTML;

    if (blogTitle.length > 0 && blogHTML.length > 0) {
      data = Object.assign({}, this.currentBlog[0], {
        blogTitle,
        blogHTML,
      });
    }

    this.$store.commit("setBlogState", data);
  },
  methods: {
    fileChange() {
      this.file = this.$refs.blogPhoto.files[0];
    },

    openPreview() {
      this.$store.commit("openPhotoPreview");
    },

    imageHandler(file, Editor, cursorLocation, resetUploader) {
      const storageRef = firebase.storage().ref();
      const docRef = storageRef.child(`documents/blogPostPhotos/${file.name}`);
      docRef.put(file).on(
        "state_changed",
        (snapshot) => {
          console.log(snapshot);
        },
        (err) => {
          console.log(err);
        },
        async () => {
          const downloadURL = await docRef.getDownloadURL();
          Editor.insertEmbed(cursorLocation, "image", downloadURL);
          resetUploader();
        }
      );
    },
    async updateDataBlog({ docRef, dataBase }) {
      const downloadURL = await docRef.getDownloadURL();

      await dataBase.update({
        blogHTML: this.blogHTML,
        blogCoverPhoto: downloadURL,
        blogCoverPhotoName: this.blogCoverPhotoName,
        blogTitle: this.blogTitle,
      });
      await this.$store.dispatch("updatePost", this.routeID);

      // Reset all
      this.$store.dispatch("resetPost");

      this.loading = false;
      this.$router.push({
        name: "ViewBlog",
        params: { blogid: dataBase.id },
      });
    },
    async updateBlog() {
      const dataBase = await db.collection("blogPosts").doc(this.routeID);
      if (this.blogTitle.length !== 0 && this.blogHTML.length !== 0) {
        if (this.file) {
          this.loading = true;
          const storageRef = firebase.storage().ref();
          const docRef = storageRef.child(
            `documents/BlogCoverPhotos/${this.$store.state.blogPhotoName}`
          );
          console.log("fileChanged", this.fileChanged);
          if (this.fileChanged) {
            docRef.put(this.file).on(
              "state_changed",
              (snapshot) => {
                console.log(snapshot);
              },
              (err) => {
                console.log(err);
                this.loading = false;
              },
              await this.updateDataBlog({ docRef, dataBase })
            );
          } else {
            await this.updateDataBlog({ docRef, dataBase });
          }

          return;
        }
        this.loading = true;
        await dataBase.update({
          blogHTML: this.blogHTML,
          blogTitle: this.blogTitle,
        });
        await this.$store.dispatch("updatePost", this.routeID);
        this.loading = false;
        this.$router.push({
          name: "ViewBlog",
          params: { blogid: dataBase.id },
        });
        return;
      }
      this.error = true;
      this.errorMsg = "Please ensure Blog Title & Blog Post has been filled!";
      setTimeout(() => {
        this.error = false;
      }, 5000);
      return;
    },
  },
};
