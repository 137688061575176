import Vue from "vue";
import Vuex from "vuex";
import firebase from "firebase/app";
import "firebase/auth";
import db from "@/firebase/firebaseInit";

Vue.use(Vuex);

const getDefaultBlogPost = () => {
  return {
    blogHTML: "Write your blog title here...",
    blogTitle: "",
    blogPhotoName: "",
    blogPhotoFileURL: null,
    blogPhotoPreview: null,
    blogPhotoCoverRaw: null,
    fileChanged: false,
  };
};

const getDefaultProfile = () => {
  return {
    profileAdmin: null,
    profileEmail: null,
    profileFirstName: null,
    profileLastName: null,
    profileUsername: null,
    profileId: null,
    profileInitials: null,
  };
};

const defaultPost = getDefaultBlogPost();
const defaultProfile = getDefaultProfile();

const actions = {
  async getCurrentUser({ commit }, user) {
    const dataBase = await db
      .collection("users")
      .doc(firebase.auth().currentUser.uid);
    const dbResults = await dataBase.get();
    commit("setProfileInfo", dbResults);
    commit("setProfileInitials");
    const token = await user.getIdTokenResult();
    const admin = await token.claims.admin;
    commit("setProfileAdmin", admin);
  },
  async getPost({ state }) {
    const dataBase = await db.collection("blogPosts").orderBy("date", "desc");
    const dbResults = await dataBase.get();
    dbResults.forEach((doc) => {
      if (!state.blogPosts.some((post) => post.blogID === doc.id)) {
        const data = {
          blogID: doc.data().blogID,
          blogHTML: doc.data().blogHTML,
          blogCoverPhoto: doc.data().blogCoverPhoto,
          blogTitle: doc.data().blogTitle,
          blogDate: doc.data().date,
          blogCoverPhotoName: doc.data().blogCoverPhotoName,
        };
        state.blogPosts.push(data);
      }
    });
    state.postLoaded = true;
  },
  async updatePost({ commit, dispatch }, payload) {
    commit("filterBlogPost", payload);
    await dispatch("getPost");
  },
  async deletePost({ commit }, payload) {
    const getPost = await db.collection("blogPosts").doc(payload);
    await getPost.delete();
    commit("filterBlogPost", payload);
  },
  async updateUserSettings({ commit, state }) {
    const dataBase = await db.collection("users").doc(state.profileId);
    await dataBase.update({
      firstName: state.profileFirstName,
      lastName: state.profileLastName,
      username: state.profileUsername,
    });
    commit("setProfileInitials");
  },
  async resetPost({ commit }) {
    console.log("should be reset");
    commit("resetPost");
  },
};
const mutations = {
  newBlogPost(state, payload) {
    state.blogHTML = payload;
  },
  updateBlogTitle(state, payload) {
    state.blogTitle = payload;
  },
  fileNameChange(state, payload) {
    state.blogPhotoName = payload;
  },
  createFileURL(state, payload) {
    state.blogPhotoFileURL = payload;
  },
  fileRaw(state, payload) {
    state.blogPhotoCoverRaw = payload;
    state.fileChanged = true;
  },
  openPhotoPreview(state) {
    state.blogPhotoPreview = !state.blogPhotoPreview;
  },
  toggleEditPost(state, payload) {
    state.editPost = payload;
  },
  setBlogState(state, payload) {
    state.blogTitle = payload.blogTitle;
    state.blogHTML = payload.blogHTML;
    state.blogPhotoFileURL = payload.blogCoverPhoto;
    state.blogPhotoName = payload.blogCoverPhotoName;
  },
  filterBlogPost(state, payload) {
    state.blogPosts = state.blogPosts.filter((post) => post.blogID !== payload);
  },
  updateUser(state, payload) {
    state.user = payload;
  },
  setProfileAdmin(state, payload) {
    state.profileAdmin = payload;
    console.log(state.profileAdmin);
  },
  setProfileInfo(state, doc) {
    state.profileId = doc.id;
    state.profileEmail = doc.data().email;
    state.profileFirstName = doc.data().firstName;
    state.profileLastName = doc.data().lastName;
    state.profileUsername = doc.data().username;
    console.log(state.profileId);
  },
  setProfileInitials(state) {
    state.profileInitials =
      state.profileFirstName.match(/(\b\S)?/g).join("") +
      state.profileLastName.match(/(\b\S)?/g).join("");
  },
  changeFirstName(state, payload) {
    state.profileFirstName = payload;
  },
  changeLastName(state, payload) {
    state.profileLastName = payload;
  },
  changeUsername(state, payload) {
    state.profileUsername = payload;
  },
  resetPost(state) {
    const defaultPostValues = getDefaultBlogPost();
    Object.keys(defaultPostValues).forEach((key) => {
      state[key] = defaultPostValues[key];
    });
  },
};

const getters = {
  getCurrentBlogHTML(state) {
    return state.blogHTML;
  },
  getCurrentBlogTitle(state) {
    return state.blogTitle;
  },
  getAllBlogPost(state) {
    return state.blogPosts;
  },
  getFileChanged(state) {
    return state.fileChanged;
  },
  blogPostsFeed(state) {
    return state.blogPosts.slice(0, 2);
  },
  blogPostsCards(state) {
    return state.blogPosts.slice(2, 6);
  },
};

export default new Vuex.Store({
  state: {
    ...defaultPost,
    ...defaultProfile,
    blogPosts: [],
    postLoaded: null,
    editPost: null,
    user: null,
  },
  getters,
  mutations,
  actions,
  modules: {},
});
