import EventBus from "@/event-bus";
import menuIcon from "@/assets/Icons/bars-regular.svg";
import userIcon from "@/assets/Icons/user-alt-light.svg";
import adminIcon from "@/assets/Icons/user-crown-light.svg";
import signOutIcon from "@/assets/Icons/sign-out-alt-regular.svg";
import menuRoutes from "../MenuRoutes";
import firebase from "firebase/app";
import "firebase/auth";

export default {
  name: "navigation",
  components: {
    menuRoutes,
    menuIcon,
    userIcon,
    adminIcon,
    signOutIcon,
  },
  data() {
    return {
      profileMenu: null,
      mobile: null,
      mobileNav: null,
      windownWidth: null,
    };
  },
  created() {
    window.addEventListener("resize", this.checkScreen);
    EventBus.$on("evt:closeNav", this.closeMobileNav);
    this.checkScreen();
  },
  beforeDestroy() {
    window.removeEventListener("resize", this.checkScreen);
    EventBus.$off("evt:closeNav");
  },
  methods: {
    checkScreen() {
      this.windownWidth = window.innerWidth;
      this.mobile = false;
      if (this.windownWidth <= 750) {
        this.mobile = true;
      }

      EventBus.$emit("evt:mobileStatus", {
        isMobile: this.mobile,
      });

      this.mobileNav = false;
      return;
    },

    toggleMobileNav() {
      this.mobileNav = !this.mobileNav;
    },
    closeMobileNav() {
      this.mobileNav = false;
    },

    toggleProfileMenu(e) {
      if (e.target === this.$refs.profile) {
        this.profileMenu = !this.profileMenu;
      }
    },

    signOut() {
      firebase.auth().signOut();
      window.location.reload();
    },
  },
  computed: {
    user() {
      return this.$store.state.user;
    },
    admin() {
      return this.$store.state.profileAdmin;
    },
  },
};
