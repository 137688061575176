import Vue from "vue";
import App from "@/App";
import router from "@/router";
import store from "@/store";
import Vue2Editor from "vue2-editor";
import "core-js/stable";
import firebase from "firebase/app";
import "firebase/auth";

Vue.use(Vue2Editor);
Vue.config.productionTip = false;

import "@/assets/reset.scss";
import "@/assets/global.scss";

let app;
firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    new Vue({
      router,
      store,
      render: (h) => h(App),
    }).$mount("#app");
  }
});
