export default {
  name: "menu-routes",
  computed: {
    user() {
      console.log("user", this.$store.state);
      return this.$store.state.user;
    },
    admin() {
      console.log("admin", this.$store.state);
      return this.$store.state.profileAdmin;
    },
  },
};
