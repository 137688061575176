import firebase from "firebase/app";
import "firebase/firestore";
/*
// Import the functions you need from the SDKs you need

import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
*/

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyCLQWGYzqRS7RVV_zPeO10bjpZdmqSSPuA",
  authDomain: "l-atelier-89e7c.firebaseapp.com",
  projectId: "l-atelier-89e7c",
  storageBucket: "l-atelier-89e7c.appspot.com",
  messagingSenderId: "477076086308",
  appId: "1:477076086308:web:d974072ee49a1dd427c2b1",
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const timestamp = firebase.firestore.FieldValue.serverTimestamp;
// const analytics = getAnalytics(app);

export { timestamp };
export default firebaseApp.firestore();
