/* eslint-disable */
import EventBus from '@/event-bus';
import splash from "@/assets/splash.svg";
import Navigation from "@/components/Navigation";
import Footer from "@/components/Footer";
import firebase from "firebase/app";
import "firebase/auth";
export default {
  name: "app",
  components: { Navigation, Footer, splash },
  data() {
    return {
      isMobile : false,
      navigation: {
        header: true,
        footer: true
      },
    };
  },
  beforeCreate() {
    EventBus.$on('evt:mobileStatus', (payload) => {
      this.isMobile = payload.isMobile
    });
  },
  created() {
    firebase.auth().onAuthStateChanged((user) => {
      this.$store.commit("updateUser", user);
      if (user) {
        this.$store.dispatch("getCurrentUser", user);
      }
    });
    this.checkRoute();
    this.$store.dispatch("getPost");
  },
  beforeDestroy() {
    EventBus.$off('evt:mobileStatus');
  },
  methods: {
    checkRoute() {
      if (
        this.$route.name === "Login" ||
        this.$route.name === "Register" ||
        this.$route.name === "ForgotPassword"
      ) {
        this.navigation = {
          header: true,
          footer: false
        };
        return;
      }
      this.navigation = {
          header: true,
          footer: true
        };
    },
  },
  watch: {
    $route() {
      this.checkRoute();
    },
  },
};