export default {
  props: ["modalMessage"],
  mounted() {
    this.$nextTick(() => {
      this.$refs.always_focus.focus();
    });
  },
  methods: {
    closeModal() {
      this.$emit("close-modal");
    },
  },
};
